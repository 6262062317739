import React, { useState } from "react";
import axios from "axios";
import { CSVLink } from "react-csv";
import { JSONHeaders } from "../../variables/SambaVideos";
import ReactLoading from "react-loading";

const SambaVideosReport = () => {
  const [onRequest, setOnRequest] = useState(false);
  const [sendCSV, setSendCSV] = useState(false);

  const downloadReport = async () => {
    setOnRequest(true);
    try {
      // Fazendo a requisição para o endpoint do relatório
      const response = await axios.get(
        "https://admportoseguro.sambalms.com.br/api/v1/account/download_samba_report",
        { responseType: "blob" } // Indicando que a resposta será um arquivo (blob)
      );

      // Criando um link temporário para o arquivo
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "SambaVideosReport.csv"); // Nome do arquivo
      document.body.appendChild(link);
      link.click();

      // Limpando o estado após o download
      setOnRequest(false);
      setSendCSV(true);
    } catch (error) {
      console.error("Erro ao baixar o relatório:", error);
      setOnRequest(false);
    }
  };

  return (
    <>
      {!onRequest && !sendCSV && (
        <button className="btn btn-info" onClick={downloadReport}>
          Baixar Relatório
        </button>
      )}
      {onRequest && <ReactLoading type="cylon" color="#1DC7EA" />}
    </>
  );
};

export default SambaVideosReport;